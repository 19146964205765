.dull-animation {
    font-size: 48px;
    color: #777; /* Dull color */
    animation: dullAnimation 2s ease-in-out infinite alternate; /* Example animation */
  }
  
  @keyframes dullAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  