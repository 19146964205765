.maintenance-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .maintenance-content {
    text-align: center;
  }
  
  .maintenance-title {
    font-size: 36px;
    color: #7e83959e;
  }
  
  .maintenance-message {
    font-size: 18px;
    color: #ffffff6b;
    margin-top: 20px;
    margin-bottom: 20px;    
    padding: 10px;
  }
  
  .countdown-timer {
    text-shadow: 2px 1px 13px #bad9ff87;
  }
  
  .countdown-text {
    font-size: 20px;
    color: #ffffff6b;
    padding: 0 10px;
    margin-bottom: 10px;

  }
  
  .countdown > span {
    font-size: 24px;
    color: #333;
    margin: 0 10px;
  }
  
