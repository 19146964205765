/* ThunderstormRain.css */

.thunderstorm-rain-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1; /* Set z-index to move the thunderstorm effect behind other content */
    width: 100%;
    height: 100%;
    pointer-events: none; /* Allow clicks to pass through the raindrops */
    background-image: url('https://images.unsplash.com/photo-1500740516770-92bd004b996e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGNsb3VkeXxlbnwwfHwwfHx8MA%3D%3D'); /* Cloudy background image */
    background-size: cover;
  }
  
  .raindrop {
    position: absolute;
    width: 1px;
    height: 5px;
    background-color: #9cbbdf; /* Raindrop color */
    animation: rain-fall 2s linear infinite, thunder-flash 2s linear infinite; /* Combine rain-fall and thunder-flash animations */
  }
  
  @keyframes rain-fall {
    0% {
      transform: translateY(-10px) rotate(26deg);
    }
    100% {
      transform: translateY(100vh) rotate(26deg);
    }
  }
  
  @keyframes thunder-flash {
    0% {
      opacity: 1; /* Fully visible */
    }
    60% {
      opacity: 0; /* Invisible */
    }
    100% {
      opacity: 1; /* Fully visible */
    }
  }
  
  .thunderstorm-rain-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(168, 168, 96, 0.635), rgba(255, 255, 0, 0)); /* Yellow lightning gradient */
    opacity: 0; /* Initially invisible */
    animation: lightning 20s linear infinite; /* Lightning animation */
  }
  
  @keyframes lightning {
    0% {
      opacity: 0; /* Invisible */
    }
    5% {
        opacity: 0; /* Invisible */
      }
    10% {
      opacity: 1; /* Fully visible */
    }
    15% {
      opacity: 0; /* Invisible */
    }
    20% {
      opacity: 1; /* Fully visible */
    }
    30% {
        opacity: 0; /* Fully visible */
    }
    
    65% {
        opacity: 0; /* Invisible */
    }
    68% {
    opacity: 1; /* Fully visible */
    }
    75% {
        opacity: 0; /* Fully visible */
    }

    100% {
      opacity: 0; /* Invisible */
    }
  }
  