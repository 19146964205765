.tic-tac-toe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
.tic-tac-toe {
margin-top: 20px;
background-color: rgba(0, 0, 0, 0); 
}

.maintenance {
    margin-top: 100px;
}

.game {
    display: inline-block;
}

.tic-tac-toe {
    margin-top: 20px;
}

.board {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-gap: 4px;
}

.board-row:after {
    clear: both;
    content: '';
    display: table;
}

.status {
    padding-bottom: 10px;
    color: #bfe1ff6b;
    font-weight: lighter;text-shadow: 0 0 #929badc4;
}

.square {
    background-color: #fff0;
    border: 1px solid #999;
    float: left;
    font-size: 22px;
    font-weight: lighter;
    line-height: 34px;
    height: 70px;
    text-align: center;
    width: 70px;
}

.square:hover {
    background-color: #f2f2f250;
}

.highlighted {
    animation: zoomIn 0.5s ease-in-out;
}

@keyframes zoomIn {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}


.dull-confetti,
.confused {
    display: block;
    font-size: 48px;
    margin-top: 20px;
}

.square .highlighted {
    background-color: yellow;
}

.replay-button {
    position: absolute; /* Set position to absolute */
    top: 58%; /* Align to center vertically */
    left: 49%; /* Align to center horizontally */
    transform: translate(-50%, -40%) rotate(-155deg); /* Center the button */
    background: none; /* Remove background */
    border: none; /* Remove border */
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .replay-icon {font-size: 200px;/* Set font size for the icon */
    color: #1f2a30cf;/* Set color for the icon */
    text-shadow: 0 0 10px #ffffff85;
  }